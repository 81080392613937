import { DISCOUNT_TYPE } from '~served/configs';
import { UndefinedOrNull } from '~served/types/utils';

import { OrderLike, calculateOrderGroup } from '../calculation';
import { convertCurrencies } from '../convertCurrencies';
import { isReceiptOffer } from '../getAppliedOffer';
import { dayjs } from '../libs/dayjs';
import locales from '../locales/locales.json';
import { GetFullNameParameters, getFullName } from '../misc';
import { orderDataHelper } from '../order';
import { toVenueTime } from '../venue';

import { GetStyleFragmentParameters, getStyleFragment } from './fragments/get-style-fragment';
import { getCombinedNotes, getCombinedOffers } from './utils';

type GetStyleFragmentParametersVenueCustomPrintingField = { label: string };

type GenerateInvoiceTemplateParametersVenue = {
	name: string;
	address: string;
	timezone: string;
	is_vat_buried: boolean;
	is_service_charge_buried: boolean;
	custom_note_instructions?: UndefinedOrNull<string>;
	vat_tin?: UndefinedOrNull<string>;
	logo: string;
	custom_printing_fields: GetStyleFragmentParametersVenueCustomPrintingField[];
	is_payment_rounding_enabled: boolean;
};

type GenerateInvoiceTemplateParametersLocation = {
	name: string;
};

type GenerateInvoiceTemplateParametersOrder = OrderLike & { note?: UndefinedOrNull<string> };

export type GenerateInvoiceTemplateParameters = {
	language?: string | null;
	split?: { count: number; amount: number };
	orders: GenerateInvoiceTemplateParametersOrder[];
	location: GenerateInvoiceTemplateParametersLocation;
	staff?: UndefinedOrNull<GetFullNameParameters>;
	venue: GetStyleFragmentParameters & GenerateInvoiceTemplateParametersVenue;
};
const generateInvoiceTemplate = ({
	language,
	location,
	orders,
	staff,
	venue,
	split,
}: GenerateInvoiceTemplateParameters) => {
	if (!orders.length) throw new Error('No orders to print');
	if (!orders.map((o) => o.items).flat().length) throw new Error('No items to print');

	const allItems = orders.map((o) => o.items).flat();
	const refOrder = orders[0];
	const combinedNotes = getCombinedNotes(orders);
	const offersUsed = getCombinedOffers(allItems);
	const {
		all: {
			subtotal,
			offer_amount,
			discount_amount,
			vat_amount,
			service_charge_amount,
			adjustment_amount,
			grand_total: _grand_total,
			$roundedGrandTotal,
		},
	} = calculateOrderGroup(orders);
	const grand_total = venue.is_payment_rounding_enabled ? $roundedGrandTotal : _grand_total;
	const vatAmountToDisplay = venue.is_vat_buried ? 0 : vat_amount;
	const serviceChargeAmountToDisplay = venue.is_service_charge_buried ? 0 : service_charge_amount;
	const staffToDisplay = staff ? getFullName(staff) : '-';
	const printDate = toVenueTime(dayjs(), venue);
	const orderedDate = toVenueTime(refOrder.created_at, venue);
	const customNoteInstruction = venue.custom_note_instructions || 'Note';
	const { $d, $d2n, getPrimaryCurrency, getPrimaryCurrencySymbol, getSecondaryCurrency } =
		orderDataHelper(refOrder);
	const primaryCurrency = getPrimaryCurrency();
	const primaryCurrencySymbol = getPrimaryCurrencySymbol();
	const secondaryCurrency = getSecondaryCurrency();
	const grandTotalSecondary = secondaryCurrency
		? convertCurrencies<string>(grand_total, primaryCurrency.code, refOrder.currencies_configs)[
				secondaryCurrency.code
		  ]
		: 0;
	const localizedText = language ? locales[language] : locales['en'];

	return /* HTML */ `
		<html>
			<head>
				${getStyleFragment(venue, 'receipt')}
			</head>
			<body>
				<div class="container">
					<div class="centered my-1">
						<img
							id="logo"
							src="${venue.logo}"
							alt="Logo"
						/>
					</div>
					<div class="centered light-bold my-1">${venue.name}</div>
					<div class="centered text-sm">${venue.address}</div>
					${venue.vat_tin ? `<div class="centered text-sm">VAT TIN: ${venue.vat_tin}</div>` : ''}

					<br />

					<div class="text-sm my-1">${localizedText.staff}: ${staffToDisplay}, ${localizedText.location}: ${
		location.name
	}</div>
					<div class="text-sm my-1">${localizedText.print}: ${printDate}, ${localizedText.ordered}: ${orderedDate}</div>
					<div class="text-sm my-1">${localizedText.orders} #: ${orders.map((o) => `#${o.index}`).join(', ')}</div>
					${combinedNotes ? `<div class="text-sm my-1">${customNoteInstruction}: ${combinedNotes}</div>` : ''}

					<hr class="divider" />

					<div class="row light-bold my-1">
						<div class="col-qty">${localizedText.qty}</div>
						<div class="col-item">${localizedText.item}</div>
						<div class="col-price">${localizedText.price}</div>
					</div>

					${allItems
						.map((item) => {
							return `
              <div class="row my-1">
                <div class="col-qty">${item.quantity}x</div>
                <div class="col-item">${item.title}</div>
                <div class="col-price">${$d(item.subtotal)}</div>
              </div>

              ${
								item.options.length
									? item.options
											.map((option) => {
												return `
                          <div class="row my-1">
                            <div class="col-qty"></div>
                            <div class="col-item">+ ${option.quantity}x ${option.title}</div>
                            <div class="col-price"></div>
                          </div>
                        `;
											})
											.join('\n')
									: ''
							}

              ${
								item.subtotal_addons?.offer?.amount &&
								item.subtotal_addons?.offer?.metadata &&
								!isReceiptOffer(item.subtotal_addons?.offer?.metadata)
									? `
                    <div class="row">
                      <div class="col-qty"></div>
                      <div class="col-item">- ${localizedText.offer} "${
											item.subtotal_addons?.offer?.metadata?.title
									  }"</div>
                      <div class="col-price">${$d(item.subtotal_addons?.offer?.amount)}</div>
                    </div>
                  `
									: ''
							}

              ${
								item.subtotal_addons?.discount?.value && !item.subtotal_addons?.discount?.is_divided
									? `
                    <div class="row">
                      <div class="col-qty"></div>
                      <div class="col-item">
                        - ${
													item.subtotal_addons?.discount?.type === DISCOUNT_TYPE.PERCENTAGE
														? '%'
														: primaryCurrencySymbol
												}${item.subtotal_addons?.discount?.value}
                      </div>
                      <div class="col-price">${$d(item.subtotal_addons?.discount?.amount)}</div>
                    </div>
                  `
									: ''
							}
            `;
						})
						.join('\n')}

					<hr class="divider" />

					<div class="my-1">${localizedText.subtotal}: <span class="float-right pr-2">${$d(subtotal)}</span></div>
					${offer_amount ? `<div class="my-1">${localizedText.offer}:</div>` : ''}
					${
						offersUsed.length
							? offersUsed
									.map((offer) => {
										return `
                      <div class="my-1">
                        - ${offer.metadata.title}
                        <span class="float-right pr-2">${$d(offer.amount)}</span>
                      </div>
                    `;
									})
									.join('\n')
							: ''
					}
					${
						discount_amount
							? `<div class="my-1">${localizedText.discount}: <span class="float-right pr-2">${$d(
									discount_amount
							  )}</span></div>`
							: ''
					}
					${
						vatAmountToDisplay
							? `<div class="my-1">${localizedText.vat}: <span class="float-right pr-2">${$d(
									vatAmountToDisplay
							  )}</span></div>`
							: ''
					}
					${
						serviceChargeAmountToDisplay
							? `<div class="my-1">${localizedText.serviceCharge}: <span class="float-right pr-2">${$d(
									serviceChargeAmountToDisplay
							  )}</span></div>`
							: ''
					}
					${
						adjustment_amount
							? `<div class="my-1">${localizedText.adjustment}: <span class="float-right pr-2">${$d(
									adjustment_amount
							  )}</span></div>`
							: ''
					}
					<div class="my-1">
						${localizedText.grandTotal} (${primaryCurrency?.code}):
						<span class="light-bold float-right pr-2">${$d(grand_total)}</span>
					</div>
					${
						secondaryCurrency
							? `
                <div class="my-1">
                  ${localizedText.grandTotal} (${
									secondaryCurrency.code
							  }): <span class="light-bold float-right pr-2 line-height-1">${$d2n(
									grandTotalSecondary
							  )}</span>
                </div>
              `
							: ''
					}
					${
						split
							? `
                <div class="my-1">
                  Split by ${split.count}: <span class="light-bold float-right pr-2 line-height-1">${$d(
									split.amount
							  )}</span>
                </div>
              `
							: ''
					}

					<br />

					${venue.custom_printing_fields
						.map(
							(field) =>
								`<div class="my-1">${field.label}: <span class="float-right pr-2">________________</span></div>`
						)
						.join('\n')}
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, '')
		.replace(/>\s*</g, '><')
		.trim();
};

export { generateInvoiceTemplate };
