import axios from 'axios';
import { Component } from 'react';
import { isNotRelease } from '~served/utils';
import { Error } from '../Error';
class ErrorBoundary extends Component<{
  appName: string;
  id: string;
  token: string;
}, {
  hasError: boolean;
}> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  async componentDidCatch(error: Error, errorInfo) {
    console.log('[APP ERROR BOUNDARY]: ', {
      error,
      errorInfo,
      location: window.location
    });
    if (isNotRelease()) return Promise.resolve();
    await axios.post(`https://discord.com/api/webhooks/${this.props.id}/${this.props.token}`, {
      embeds: [{
        title: error.message || 'Unknown Error',
        color: 14553104,
        description: `**${this.props.appName}** - **${process.env.ENV!.toUpperCase()}** - **${process.env.VERSION}** \n ${new Date().toUTCString()} \n\n ${JSON.stringify(window.location, null, 2)} \n ${errorInfo.componentStack ? errorInfo.componentStack.slice(0, 256) + '...' : ''} `
      }]
    }).catch(console.error);
  }
  render() {
    if (this.state.hasError) {
      return <Error data-sentry-element="Error" data-sentry-component="ErrorBoundary" data-sentry-source-file="index.tsx" />;
    }
    return this.props.children;
  }
}
export { ErrorBoundary };